.image-wrapper::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  transition: var(--transition);
  border: 2px solid var(--yellow);
  top: 20px;
  left: 20px;
  z-index: -1;
}
.image-wrapper-hover::after {
  top: 10px;
  left: 10px;
}
.picture {
  background-color: rgb(232, 232, 232);
  filter: blur(3px);

  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: var(--border-radius);
  transition: var(--transition);

  height: 300px;
  width: 300px;
}
.picture:hover {
  filter: unset;
}
.image-wrapper {
  position: relative;
  max-width: 300px;
}
.image-text {
  position: absolute;
}
.picture > img {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.picture > img.loaded {
  opacity: 1;
}
@media only screen and (max-width: 768px) {
  .picture {
    height: 200px;
    width: 200px;
  }
}
