header {
  backdrop-filter: blur(10px);
  height: clamp(70px, 10vh, 120px);
}
header > div {
  max-width: 1200px;
}
.header-link::after {
  content: "";
  display: block;
  width: 0px;
  height: 1px;
  position: relative;
  bottom: 1px;
  background-color: var(--yellow);
  transition: 0.2s all;
  opacity: 0.5;
}
.header-link:hover::after {
  width: 100%;
}
.header-link {
  scroll-behavior: smooth;
  color: var(--dark-white) !important;
  margin: auto 10px !important;
  font-size: inherit !important;
  font-family: inherit !important;
  text-decoration: none;
  padding: 0 !important;
}
.header-link:hover {
  color: var(--yellow);
}
.header-link::before {
  content: counter(item, decimal-leading-zero) ".";
  margin-right: 5px;
  color: var(--yellow);
  counter-increment: item 1;

  font-size: var(--fz-xxs);
  text-align: right;
}
.mobile-header {
  counter-reset: item 0;
}

.MuiDrawer-paper {
  background-color: var(--black) !important;
  width: clamp(200px, 40vw, 500px);
}
.dropdown-menu
  > .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  max-width: unset;
  width: 130px;
}
.my-button {
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(25, 118, 210, 0.5);
  color: #1976d2;
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
  padding: 12px 24px;
  border: 1px solid;
  color: var(--yellow);
  line-height: 1.5;
  background-color: transparent;
  border-color: var(--yellow);
}
.my-button:hover {
  background-color: #3d3926;
  border-color: var(--yellow);
  box-shadow: none;
}
