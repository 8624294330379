h1 {
  margin: 0;
}
#content {
  padding: 0px 150px;
}
.italic {
  font-style: italic;
}
.intro-text {
  text-align: left;
}
.text {
  color: var(--dark-white);
  line-height: 1.5;
}
.intro-text > h1 {
  font-size: var(--font-size--main-big-clamp);
}
.sub-big > h1 {
  margin-top: 10px;
  font-size: var(--font-size--sub-big-clamp);
}
.yellow {
  color: var(--yellow);
}
.main-intro > * > p {
  font-size: var(--font-size--body-text);
  max-width: 60vw;
  line-height: 2;
}

#main-section {
  color: var(--white);
  counter-reset: section 0;
  margin: 70px auto;
  max-width: 1000px;
  padding-top: 50px;
  display: flex;
  height: 80vh;
}
.dark-white {
  color: var(--dark-white);
}
.numbered-heading {
  display: flex;
  color: var(--white);
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  margin: 10px 0px 40px;
  width: 100%;
  font-size: clamp(var(--font-size--clamp));
  white-space: nowrap;
}

.numbered-heading::before {
  position: relative;
  counter-increment: section 1;

  content: counter(section, decimal-leading-zero) ".";
  margin-right: 10px;
  color: var(--yellow);
  /* font-family: var(--font-mono); */
  font-size: clamp(var(--font-size--clamp));
  font-weight: 400;
}
.numbered-heading::after {
  content: "";
  display: block;
  position: relative;
  width: 300px;
  height: 1px;
  margin-left: 20px;
  background-color: var(--gray);
}
#about-me {
  max-width: 1000px;
  margin: auto;
}
.about-me-inner {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 50px;
}
.about-me-desc {
  text-align: left;
  font-size: var(--font-size--body-text);
}
.skill-list > li {
  display: flex;
  list-style: none;
  font-size: 16px;
  justify-content: flex-start;
  align-items: center;
}
.skill-list {
  margin: 30px 0px;
  display: grid;
  grid-template-columns: repeat(2, minmax(140px, 200px));
  gap: 20px 10px;
  padding: 0;
}
#job-experience {
  max-width: 700px;
  margin: auto;
}
@media only screen and (max-width: 1080px) {
  #content {
    padding: 0 100px;
  }
  .numbered-heading::after {
    width: 200px;
  }
}
@media only screen and (max-width: 768px) {
  #content {
    padding: 0 50px;
  }
  .numbered-heading::after {
    width: 100%;
  }
  .about-me-inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 480px) {
  #content {
    padding: 0 25px;
  }
  .main-intro > * > p {
    max-width: 100%;
    line-height: 2;
  }
}
.mobile-tabpanel > div > div > div {
  font-size: var(--font-size--mobile-title);
}
section {
  padding: 100px 0;
}
#my-project {
  margin: 0 30px;
}
section.activeSection {
  transform: none !important;
  opacity: 1;
  transition: 1s all;
}

section:not(#main-section) {
  transform: translateY(50px);
}
section {
  opacity: 0;
  scroll-margin-top: 50px;
}
.numbered-heading.small-heading::after {
  display: none;
}
.numbered-heading.small-heading {
  font-size: 16px;
  color: var(--yellow);
  display: block;
  margin-bottom: 20px;
}
.numbered-heading.small-heading::before {
  font-size: 16px;
}
#contact-me {
  max-width: 600px;
  margin: auto;
}
.title {
  font-size: clamp(40px, 5vw, 60px);
  margin: 20px;
}
