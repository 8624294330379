.pokemon-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 30px;
  max-width: 1000px;
  margin: 20px auto;
  opacity: 0;
}
.show {
  opacity: 1;
  transition: 0.5s opacity;
}
.pokemon-section > div > h4 {
  text-transform: capitalize;
}
.MuiPagination-ul {
  justify-content: center;
}
.pokemon-section > div {
  width: 20%;
  position: relative;
}
.types {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 20px;
  margin-top: 0;
}
.types > p {
  width: 70px;
  border-radius: 70px;
  color: white;
  padding: 5px;
  margin: 0;
  font-weight: bold;
}
.pokemon-section > div > button {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: none;
}
.pokemon-section > div > button > i {
  color: #e8ba10;
}
@media only screen and (max-width: 950px) {
  .pokemon-section > div {
    width: 40%;
  }
  .pokemon-section {
    justify-content: center;
  }
}
@media only screen and (max-width: 480px) {
  .pokemon-section > div {
    width: 80%;
  }
}
.MuiPaginationItem-circular {
  color: var(--dark-white) !important;
}
.MuiPaginationItem-circular.Mui-selected {
  background-color: var(--yellow) !important;
  color: var(--black) !important;
}
.pokemon-section > div {
  height: 260px;
}
